import { Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { color } from '../../Styles/Color';

function createData(date, amount, type, status) {
  amount = Math.round(amount * 1000) / 1000
  const newdate = new Date(date * 1000);
  date = newdate.toISOString().split(".")[0].replace("T", " ");
  return { date, amount, type, status };
}

export default function TransactionList({ data }) {

  const rows = (data || []).map((d) =>
    createData(d.created_at, d.amount, d.order_type, d.status));

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000029",
        maxHeight: "50vh",
      }}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Amount ($)</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">
              Transaction Type
            </TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Created At</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.map((row, idx) => (
                <TableRow
                  key={idx}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ fontSize: "13px" }}>
                    $ {row.amount}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>
                    {row.type}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>
                    {row.date}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>
                    {row.status}
                  </TableCell>
                </TableRow>
              )) :
              <Stack height="10vh" justifyContent="center" px={2}>
                <Typography color={color.primary} fontSize="15px">No data available</Typography>
              </Stack>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import SubscriptionModal from '../Components/BillingComponents/SubscriptionModal';
import TopUpModal from '../Components/BillingComponents/TopUpModal';
import TransactionList from '../Components/BillingComponents/TransactionList';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { useGetBalanceQuery, useGetTransactionsQuery } from '../Services/billingApi';
import { color } from '../Styles/Color';

export const BillingView = () => {

  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false)
  const [isSubsModalOpen, setIsSubsModalOpen] = useState(false)

  const { data, isLoading: loadingBalance, refetch: refetchBalance } = useGetBalanceQuery()
  const { data: transactions, isLoading: loadingTransactions, refetch: refetchTransaction } = useGetTransactionsQuery()

  if (loadingBalance || loadingTransactions) {
    return <Stack height="80%">
      <Loader />
    </Stack>
  }

  return (
    <Stack
      spacing={4}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
      p={1}
    >
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px">Billing</Typography>
      <Stack direction="row" gap={3} width="90%">
        <Stack
          border="2px solid"
          borderColor={color.borders}
          borderRadius="8px"
          boxShadow="0px 2px 4px 0px #0000001F"
          width="45%"
          p={2}
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography fontSize="15px"> Current Balance </Typography>
            <Typography fontWeight={600} fontSize="20px">
              $ {data && Math.round(data.regular_balance * 1000) / 1000}
            </Typography>
          </Stack>
          <Stack alignItems="end">
            <CustomButton onClick={() => setIsTopUpModalOpen(true)} width="50%">
              Top Up Balance
            </CustomButton>
          </Stack>
        </Stack>
        <Stack
          border="2px solid"
          borderColor={color.borders}
          borderRadius="8px"
          boxShadow="0px 2px 4px 0px #0000001F"
          width="45%"
          p={2}
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography fontSize="15px">
              Subscription Balance {`( ${data.months_left || 0} months left )`}
            </Typography>
            <Typography
              fontWeight={600} fontSize="20px"
              display="flex" flexDirection="row"
              alignItems="center"
              gap={3}
            >
              $ {data && Math.round(data.discount_balance * 1000) / 1000}
              <Typography fontSize="16px">
                ( $ {`${data && Math.round(data.refill_per_month * 1000) / 1000} / month `})
              </Typography>
            </Typography>
          </Stack>
          <Stack alignItems="end">
            <CustomButton onClick={() => setIsSubsModalOpen(true)} width="50%">
              Add Subscription
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography fontFamily="IBMPlexSansSemiBold" fontSize="20px">Transaction History</Typography>
        <TransactionList data={transactions} />
      </Stack>
      <TopUpModal
        isOpen={isTopUpModalOpen} setIsOpen={setIsTopUpModalOpen}
        refetchBalance={refetchBalance} refetchTransaction={refetchTransaction}
      />
      <SubscriptionModal
        isOpen={isSubsModalOpen} setIsOpen={setIsSubsModalOpen}
        refetchBalance={refetchBalance} refetchTransaction={refetchTransaction}
      />
    </Stack>
  )
}

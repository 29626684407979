// import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { Autocomplete, Chip, FormControlLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect } from 'react';
import { FINE_TUNE_DATASET_OPTIONS } from '../../../Configs/ConfigureNewJobConstants';
import { color } from '../../../Styles/Color';
import BoxSwitch from '../../UiComponents/BoxSwitch';
import CustomToggleButton from '../../UiComponents/CustomToggleButton';
import InputField from '../../UiComponents/InputField';



export const DataSources = ({
  typeSelected, setTypeSelected, vmOptions, modelList, register, setValue, errors, watch, artifactStoragePaths
}) => {

  useEffect(() => {

    setValue("data_path", "")
    if (typeSelected === FINE_TUNE_DATASET_OPTIONS[0]) {
      setValue("user_dataset", "")
    }

  }, [setValue, typeSelected])

  return (
    <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Chip
        sx={
          {
            bgcolor: "#FFFFFF",
            color: color.primary,
            width: "25%",
            borderRadius: "6px",
            fontSize: "15px"
          }
        }
        label={
          <Stack gap={1} direction="row" alignItems="center">
            <IntegrationInstructionsIcon sx={{ color: color.primary, fontSize: "16px" }} />
            Data Sources
          </Stack>
        } />
      <Stack gap={1} >
        <Typography fontSize="15px">Model</Typography>
        <Autocomplete
          size="small"
          sx={{ fontSize: '15px', width: "70%", background: '#fff', borderRadius: "8px" }}
          value={watch("model")}
          onChange={(e, newValue) => setValue("model", newValue)}
          options={modelList}
          renderInput={(params) =>
            <TextField {...params}
              InputProps={{ ...params.InputProps, style: { fontSize: "15px", borderRadius: '8px' } }}
              placeholder="Choose a Model"
              error={errors?.model ? true : false}
            />
          }
          disableClearable
          freeSolo
          renderOption={(props, option, state) => <Typography {...props} fontSize="15px">{option}</Typography>}
        />
      </Stack>
      {
        errors?.model &&
        <Typography color={color.error} fontSize="15px">This field is required</Typography>
      }
      <CustomToggleButton options={FINE_TUNE_DATASET_OPTIONS}
        selected={typeSelected} setSelected={setTypeSelected} />
      {
        typeSelected === FINE_TUNE_DATASET_OPTIONS[0]
          ?
          <Stack spacing={2}>
            <Stack spacing={1} >
              <Typography fontSize="15px" >HuggingFace Dataset Name</Typography>
              <InputField
                placeholder="Enter HuggingFace dataset"
                register={register} field="data_path" watch={watch}
                width="70%" />
            </Stack>

          </Stack>
          :
          <Stack spacing={2} >
            <Select
              displayEmpty
              size='small'
              sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
              value={watch("data_path")}
              onChange={(e) => setValue("data_path", e.target.value)}
            >
              <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
                Choose Data Store
              </MenuItem>
              {
                vmOptions?.map((vm, idx) => (
                  <MenuItem value={vm.name} key={idx} sx={{ fontSize: "15px" }}>
                    {vm.name}
                  </MenuItem>
                ))
              }
            </Select>
            <Stack spacing={1} >
              <Typography fontSize="15px" >File Name</Typography>
              <InputField
                placeholder="Enter File name"
                register={register} field="user_dataset" watch={watch}
                width="70%" />
            </Stack>

          </Stack>
      }
      {
        errors.dataset &&
        <Typography color={color.error} fontSize="15px">This section is required</Typography>
      }
      <FormControlLabel sx={{ p: 2 }}
        value={watch("push_to_hub")}
        control={
          <BoxSwitch
            onChange={(e) => setValue("push_to_hub", e.target.checked)}
          />
        }
        label={<Typography fontSize="17px" mx={1}>Push to HuggingFace Model Hub</Typography>}
      />
      {
        watch("push_to_hub") &&
        <>
          <Stack gap={1} >
            <Typography fontSize="15px">Repo Id</Typography>
            <InputField placeholder="Enter repo id"
              // state={faceToken} setState={(e) => setFaceToken(e.target.value)} 
              register={register}
              watch={watch}
              field="repo_id"
              error={errors?.push_to_hub?.repo_id}
              // required={true}
              width="70%"
            />
          </Stack>
          <Stack gap={1} >
            <Typography fontSize="15px">Username</Typography>
            <InputField placeholder="Enter username"
              // state={faceToken} setState={(e) => setFaceToken(e.target.value)} 
              register={register}
              watch={watch}
              field="username"
              error={errors?.push_to_hub?.username}
              // required={true}
              width="70%"
            />
          </Stack>
        </>
      }
      <Stack spacing={1} >
        <Typography fontSize="15px" >HuggingFace Token</Typography>
        <InputField
          placeholder="Enter HuggingFace Token"
          register={register} field="hf_token" watch={watch}
          width="70%" />
      </Stack>

      <Typography fontWeight="600" fontSize="15px">
        Checkpoint Store
      </Typography>
      <Select
        displayEmpty
        size='small'
        sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
        value={watch("artifacts_storage")}
        onChange={(e) => setValue("artifacts_storage", e.target.value)}
        error={errors["artifacts_storage"]}
      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          Choose Checkpoint Store (Artifact Storage)
        </MenuItem>
        {
          artifactStoragePaths?.map((path, idx) => (
            <MenuItem value={path.name} key={idx} sx={{ fontSize: "15px" }}>
              {path.name}
            </MenuItem>
          ))
        }
      </Select>
    </Stack >
  )
}


// // NAVBAR ITEMS
// Admin Section Items
export const ADMIN = "ADMIN";
export const DASHBOARD = "DASHBOARD";
export const CLOUD_PROVIDERS = "CLOUD_PROVIDERS";
export const VIRTUAL_MOUNTS = "VIRTUAL_MOUNTS";
export const ARTIFACTS_STORAGES = "ARTIFACTS_STORAGES";
export const BILLING = "BILLING";
export const SECRETS = 'SECRETS';
export const MEMBERS = "MEMBERS";
export const ON_PREM_NODES = "ON_PREM_NODES";
export const LLM_PROVIDERS = "LLM_PROVIDERS";
export const GPU_MACHINES = "GPU_MACHINES";
// Training Section Items
export const TRAINING = "TRAINING";
// Templates Section Items
export const TEMPLATES = "TEMPLATES";
// Inference Section Items
export const INFERENCE = "INFERENCE";
// LLM Gateway Section Items
export const LLM_GATEWAY = "LLM_GATEWAY";
// Misc Section Items
export const ACCESS_KEYS = "ACCESS_KEYS";
export const USAGE = "USAGE";


// -------------------------------

export const ADD_CLOUD_PROVIDER = 'ADD_CLOUD_PROVIDER'
export const ADD_VIRTUAL_MOUNT = 'ADD_VIRTUAL_MOUNT'
export const ADD_ARTIFACTS_STORAGE = 'ADD_ARTIFACTS_STORAGE'
export const ADD_SECRET = 'ADD_SECRET'
export const ADD_MEMBER = 'ADD_MEMBER'

export const VIEW_ACCESS_KEY = 'VIEW_ACCESS_KEY'

export const JOBS = 'JOBS'
export const LAUNCH_NEW_JOB = 'LAUNCH_NEW_JOB'
export const LAUNCH_NEW_JOB_WITH_TEMPLATE = 'LAUNCH_NEW_JOB_WITH_TEMPLATE'
export const RETRY_JOB = 'RETRY_JOB'
export const VIEW_JOB = 'VIEW_JOB'

export const CREATE_SIMPLE_JOB_TEMPLATE = 'CREATE_SIMPLE_JOB_TEMPLATE'
export const VIEW_SIMPLE_JOB_TEMPLATE = 'VIEW_SIMPLE_JOB_TEMPLATE'

export const ARTIFACTS = 'ARTIFACT'

export const EDIT_PERMISSIONS = 'EDIT_PERMISSIONS'

export const VIEW_JOB_USAGE = 'VIEW_JOB_USAGE'

export const AZURE = "Azure"

export const AWS = "AWS"

export const GCP = "GCP"

export const DATACRUNCH = "DATACRUNCH"

export const LAMBDA = "Lambda"

export const EXOSCALE = "EXOSCALE"

export const CUDO = "CUDO"

export const CORE_WEAVE = "Core Weave"

export const VASTAI = "VASTAI"

export const ANTHROPIC = "Anthropic"

export const RUNPOD = "RUNPOD"

export const getInferenceCertificate = (id, type, controller_ip) => (
  `## Connection Setup


### Downloading the cert


You can download the certificate by clicking the button below

  <Button Button > DOWNLOAD CERTIFICATE</Button >

or download using the scalegen cli


~~~shell
scalegen infer download_cert ${id}
~~~

### Registering the cert

~~~shell
mv ${id}.crt /usr/local/share/ca-certificates/${id}.crt
sudo update-ca-certificates
echo '${controller_ip} ${id}.localhost' | sudo tee -a /etc/hosts
~~~

### Test

  ${type === 'llm' ?
    `~~~shell
  curl -X POST 
-L https://${id}.localhost/inference/v1/chat/completions 
-H "Authorization: Bearer <API_KEY>" 
-H "Content-Type: application/json" 
-d '{
        "model": "<model>",
        "messages": [
                        {
                            "role": "system",
                            "content": "You are a useful assistant"
                        },
                        {
                            "role": "user",
                            "content": "Hello"
                        }
        ],
        "max_tokens":10,
        "stream":true
}'
~~~` : ""
  }

  ${type === "embedding" ?
    `
  ~~~shell
  curl -L https://${id}.localhost/embed/v1/embeddings  
  -X POST 
  -d '{"input":"What is Deep Learning?"}' 
  -H 'Content-Type: application/json' 
  -H 'Authorization: Bearer <api key>'
  ~~~
  ` : ""
  }

`
)
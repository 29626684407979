import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import AddMemberModal from '../Components/TeamViewComponents/AddMemberModal'
import Header from '../Components/TeamViewComponents/Header'
import TeamList from '../Components/TeamViewComponents/TeamList'
import Loader from '../Components/UiComponents/Loader'
import { useDeleteMemberMutation, useVerifyUserMutation } from '../Services/userApi'

const TeamView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  // const user_metadata = useSelector(store => store?.userState?.sgUser?.user_metadata)
  const [verifyUser, { data: sgUser, isLoading: isUserVerifing }] = useVerifyUserMutation();

  const [deleteMember, { isLoading: isDeleting, isSuccess }] = useDeleteMemberMutation()

  useEffect(() => {
    verifyUser()
  }, [verifyUser])

  useEffect(() => {
    if (isSuccess) {
      verifyUser()
    }
  }, [isSuccess, verifyUser])

  const onDelete = (email) => {
    deleteMember({ "email": email, "productType": "SCALEGEN" })
  }

  if (isDeleting || isUserVerifing) {
    return <Stack height="80vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack gap={2}>
      <Header handleAdd={() => setIsModalOpen(true)} />
      <TeamList data={sgUser?.user_metadata?.member_users} handleDelete={onDelete} />
      <AddMemberModal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} refetch={verifyUser} />
    </Stack>
  )
}

export default TeamView
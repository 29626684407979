import ClearIcon from '@mui/icons-material/Clear';
import TopicIcon from '@mui/icons-material/Topic';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeStorageModal } from '../../DataStore/modalSlice';
import { useCreateArtifactStoragePathMutation } from '../../Services/artifactStorageApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import Loader from '../UiComponents/Loader';
import ChooseStorageType from './ChooseStorageType';
import StorageDataForm from './StorageDataForm';

const AddPathModal = () => {
  const [isActive, setIsActive] = useState("");
  const [storageType, setStorageType] = useState("");
  const initialFormState = Object.freeze({
    name: "",
    path: "",
    credentials: {}
  })

  const [formValues, setFormValues] = useState(initialFormState)

  const [handleSubmit, { isLoading, isSuccess }] = useCreateArtifactStoragePathMutation();
  const { isStorageModalOpen } = useSelector((store) => store.modal)

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleClose = () => {
    setIsActive("")
    setStorageType("")
    dispatch(closeStorageModal())
  }

  const handleNext = () => {
    setStorageType(isActive)
  }

  const handleAdd = () => {
    handleSubmit(formValues);
  }


  return (
    <Dialog maxWidth="xl" open={isStorageModalOpen} onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <TopicIcon fontSize='20px' sx={{ color: color.primary }} />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>Add Artifact Storage</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "50vh",
        width: "55vw", p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        {
          isLoading ?
            <Stack height="50vh">
              <Loader />
            </Stack>
            :
            <>
              {
                storageType === "" ?
                  <ChooseStorageType isActive={isActive} setIsActive={setIsActive} /> :
                  <StorageDataForm
                    formValues={formValues}
                    setFormValues={setFormValues}
                    storageType={storageType}
                  />
              }
            </>
        }
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton
          onClick={!storageType ? handleNext : handleAdd}
          width="10%"
        >
          {!storageType ? "Next" : "Add"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddPathModal
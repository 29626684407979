import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import DataUsageRoundedIcon from '@mui/icons-material/DataUsageRounded';
import DnsIcon from '@mui/icons-material/Dns';
import DvrIcon from '@mui/icons-material/Dvr';
// import InsightsIcon from '@mui/icons-material/Insights';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import ModelTrainingRoundedIcon from '@mui/icons-material/ModelTrainingRounded';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import IconButton from '@mui/material/IconButton';
import { deepFreeze } from "../Utils";
import {
    //ACCESS_KEYS,
    ADMIN, ARTIFACTS_STORAGES, BILLING, CLOUD_PROVIDERS, GPU_MACHINES, INFERENCE,
    // LLM_GATEWAY, LLM_PROVIDERS, TEMPLATES
    MEMBERS, ON_PREM_NODES, SECRETS, TRAINING,
    //USAGE,
    VIRTUAL_MOUNTS
} from './Constants';
import { PAGE_ROUTES } from "./Routes";


export const ADMIN_MENU_ITEMS = {
    [ADMIN]: {
        id: ADMIN,
        icon: <AdminPanelSettingsRoundedIcon fontSize="small" />,
        label: 'Admin',
        items: [
            {
                id: ON_PREM_NODES,
                icon: () => <DvrIcon fontSize="small" />,
                label: 'On-Prem Nodes',
                redirectUrl: PAGE_ROUTES.onPremNodes
            },
            {
                id: CLOUD_PROVIDERS,
                icon: () => <CloudRoundedIcon fontSize="small" />,
                label: 'Cloud Providers',
                redirectUrl: PAGE_ROUTES.cloudProviders
            },
            {
                id: VIRTUAL_MOUNTS,
                icon: () => <StorageRoundedIcon fontSize="small" />,
                label: 'Virtual Mounts',
                redirectUrl: PAGE_ROUTES.virtualMounts
            },
            {
                id: ARTIFACTS_STORAGES,
                icon: () => <SaveAsRoundedIcon fontSize="small" />,
                label: 'Artifact Storages',
                redirectUrl: PAGE_ROUTES.artifactStorages
            },
            {
                id: BILLING,
                icon: () => <PaymentIcon fontSize="small" />,
                label: 'Billing',
                redirectUrl: PAGE_ROUTES.billing
            },
            {
                id: SECRETS,
                icon: () => <SecurityRoundedIcon fontSize="small" />,
                label: 'Secrets',
                redirectUrl: PAGE_ROUTES.secrets
            },
            {
                id: MEMBERS,
                icon: () => <PersonRoundedIcon fontSize="small" />,
                label: 'Members',
                redirectUrl: PAGE_ROUTES.team
            },
            // {
            //     id: LLM_PROVIDERS,
            //     icon: () => <InsightsIcon fontSize="small" />,
            //     label: 'LLM Providers',
            //     redirectUrl: PAGE_ROUTES.llmProviders
            // }
        ],
    },
};
deepFreeze(ADMIN_MENU_ITEMS);


export const SIDE_NAVBAR_JOB_MENU_ITEMS = [
    {
        id: TRAINING,
        icon: <IntegrationInstructionsRoundedIcon fontSize="small" />,
        label: 'Training',
        redirectUrl: PAGE_ROUTES.training
    },
    // {
    //     id: TEMPLATES,
    //     icon: <TextSnippetRoundedIcon fontSize="small" />,
    //     label: 'Templates',
    //     redirectUrl: PAGE_ROUTES.templates
    // },
    {
        id: INFERENCE,
        icon: <ModelTrainingRoundedIcon fontSize="small" />,
        label: 'Inference',
        redirectUrl: PAGE_ROUTES.inferences
    },
    // {
    //     id: LLM_GATEWAY,
    //     icon: <AccountTreeRoundedIcon fontSize="small" />,
    //     label: 'LLM Gateway',
    //     redirectUrl: PAGE_ROUTES.llmGateways
    // },
    {
        id: GPU_MACHINES,
        icon: () => <DnsIcon fontSize="small" />,
        label: 'GPU Machines',
        redirectUrl: PAGE_ROUTES.machines
    },
];
deepFreeze(SIDE_NAVBAR_JOB_MENU_ITEMS);


export const SIDE_NAVBAR_INFO_MENU_ITEMS = [
    // {
    //     id: ACCESS_KEYS,
    //     icon: <KeyRoundedIcon fontSize="small" />,
    //     label: 'Access Keys',
    // },
    // {
    //     id: USAGE,
    //     icon: <DataUsageRoundedIcon fontSize="small" />,
    //     label: 'Usage',
    // }
];
deepFreeze(SIDE_NAVBAR_INFO_MENU_ITEMS);


export const MENU_ITEM_ICONS = Object.freeze({
    'ADMIN': <>
        <IconButton>
            <AdminPanelSettingsRoundedIcon fontSize="small" />
        </IconButton>
    </>,

    'CLOUD_PROVIDERS': <>
        <IconButton>
            <CloudRoundedIcon fontSize="small" />
        </IconButton>
    </>,

    'VIRTUAL_MOUNTS': <>
        <IconButton>
            <StorageRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'ARTIFACTS_STORAGES': <>
        <IconButton>
            <SaveAsRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'SECRETS': <>
        <IconButton>
            <SecurityRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'MEMBERS': <>
        <IconButton>
            <PersonRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'TRAINING': <>
        <IconButton>
            <IntegrationInstructionsRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'TEMPLATES': <>
        <IconButton>
            <TextSnippetRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'INFERENCE': <>
        <IconButton>
            <ModelTrainingRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'LLM_GATEWAY': <>
        <IconButton>
            <AccountTreeRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'ACCESS_KEYS': <>
        <IconButton>
            <KeyRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'USAGE': <>
        <IconButton>
            <DataUsageRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'DOCUMENTATION': <>
        <IconButton>
            <ArticleRoundedIcon fontSize="small" />
        </IconButton>
    </>,
    'GPU_MACHINES': <>
        <IconButton>
            <DnsIcon fontSize="small" />
        </IconButton>
    </>
})

import { createApi } from '@reduxjs/toolkit/query/react';
import { platformAuthConfig } from './platformAuthConfig.js';

const billingApi = createApi({
  reducerPath: 'billingApi',
  ...platformAuthConfig(),
  tagTypes: ['Balance', 'Transactions'],
  endpoints: (builder) => ({
    topUpBalance: builder.mutation({
      query: (config) => ({
        url: "/billing/top_up_balance",
        method: "POST",
        body: config
      }),
      // invalidatesTags: ['Balance', 'Transactions'],
    }),
    createSubscription: builder.mutation({
      query: (config) => ({
        url: "/billing/create_subscription",
        method: "POST",
        body: config
      }),
      // invalidatesTags: ['Balance', 'Transactions'],
    }),
    getBalance: builder.query({
      query: () => ({
        url: `/billing/get_subscription`,
        method: "GET",
      }),
      providesTags: ['Balance']
    }),
    getTransactions: builder.query({
      query: () => ({
        url: `/billing/transactions`,
        method: "GET",
      }),
      providesTags: ['Transactions']
    })
  }),
})

export const {
  useTopUpBalanceMutation,
  useCreateSubscriptionMutation,
  useGetBalanceQuery,
  useGetTransactionsQuery
} = billingApi;

export default billingApi
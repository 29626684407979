import ClearIcon from '@mui/icons-material/Clear';
// import DvrIcon from '@mui/icons-material/Dvr';
import AddCardIcon from '@mui/icons-material/AddCard';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setIsError } from '../../DataStore/errorSlice';
import { setIsSuccess, setSuccessMessage } from '../../DataStore/successSlice';
import { useTopUpBalanceMutation } from '../../Services/billingApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import InputField from '../UiComponents/InputField';
import { getCardElement, StripeCard } from './StripeCard';


const TopUpModal = ({ isOpen, setIsOpen, refetchBalance, refetchTransaction }) => {

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()

  const user = useSelector(state => state.userState.sgUser)

  const [topUpBalnce, { isLoading: topingUp }] = useTopUpBalanceMutation()

  const [amount, setAmount] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClose = useCallback(() => {
    setIsProcessing(false)
    setAmount("")
    setIsOpen(false)
  }, [setIsOpen])

  const handlePay = async (event) => {
    event.preventDefault();

    if (elements === null) {
      return;
    }

    setIsProcessing(true)

    topUpBalnce({
      amount: amount,
      payment_method_type: 'card'
    })
      .unwrap()
      .then(async res => {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          res.clientSecret,
          {
            payment_method: {
              card: getCardElement(),
              // customer: stripe_customer_id,
              billing_details: {
                name: user.email,
              },
            },
          }
        );

        if (error) {
          dispatch(setIsError(true))
          dispatch(setErrorMessage(error.message || "Payment Declined"))
          setIsProcessing(false)
        } else {
          dispatch(setIsSuccess(true))
          dispatch(setSuccessMessage("Payment " + paymentIntent.status))
          refetchBalance()
          refetchTransaction()
          handleClose()
        }
      })
  }

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <AddCardIcon fontSize='20px' sx={{ color: color.primary }} />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>Top-Up Balance</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent
        sx={{
          height: "35vh",
          width: "35vw",
          p: 2,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Stack spacing={4} alignItems="center" justifyContent="center" height="30vh">
          <Stack spacing={2} width="90%">
            <Typography fontSize="15px">Payment Amount ($) </Typography>
            <InputField
              state={amount}
              setState={(e) => setAmount(e.target.value)}
              type='number'
              width="100%"
            />
          </Stack>
          <StripeCard />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton width="60%" onClick={handlePay}
          disabled={!stripe || !elements || isProcessing || amount.length === 0 || topingUp}
        >
          {isProcessing || topingUp ? "Processing Payment ..." : "Pay"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default TopUpModal
import { Stack } from '@mui/material';
import { CardElement, useElements } from '@stripe/react-stripe-js';

const cardElementOptions = {
  style: {
    base: {
      fontFamily: 'IBMPlexSansSemiBold',
      fontSize: "17px",
    }
  }
}

let cardElement;

export const getCardElement = () => {
  return cardElement
}

export const StripeCard = ({ getCardElement }) => {
  const elements = useElements();

  if (elements !== null) {
    cardElement = elements.getElement(CardElement)
  }

  return (
    <Stack width="90%">
      <CardElement options={cardElementOptions} />
    </Stack>
  )
}

import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/material';
import * as Sentry from "@sentry/react";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import NavBar from '../Components/NavBarComponents/NavBar';
import { PAGE_ROUTES } from '../Configs/Routes';
import { setPlatformBearer, setSgUser, setUser } from '../DataStore/userSlice';
import { useVerifyUserMutation } from '../Services/userApi';
import LoadingView from './LoadingView';

const Layout = () => {
  const isAdmin = useSelector((state) => state.userState.sgUser?.user_metadata?.is_admin);
  const nav = useNavigate()
  const dispatch = useDispatch()
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const platformBearer = useSelector((state) => state.userState.platformBearer);
  const [verifyUser, { data: sgUser, isLoading: isUserVerifing, isSuccess }] = useVerifyUserMutation();
  const [appLoading, setAppLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading && isAuthenticated) {
        dispatch(setUser(user));
        Sentry.setUser({ email: user.email });
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:all",
        });
        dispatch(setPlatformBearer(token));
        verifyUser();
      }
    };

    fetchData();
  }, [user, isAuthenticated, isLoading, dispatch, platformBearer, getAccessTokenSilently, verifyUser]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSgUser(sgUser))
    }
  }, [dispatch, isSuccess, sgUser]);

  useEffect(() => {
    if (!isLoading && !isUserVerifing) {
      setAppLoading(false)
    }
  }, [isLoading, isUserVerifing])

  if (!isAuthenticated && !isLoading) {
    nav(PAGE_ROUTES.login)
  }

  return (
    <>
      {
        appLoading
          ?
          <LoadingView />
          :
          <Stack width="100vw" height="100vh" direction="row">

            <Stack width="20%" height="100%">
              <NavBar isAdmin={isAdmin} />
            </Stack>
            <Stack
              width="79%"
              height="97%"
              p={3}
              borderRadius={2}
              my={1}
              border="2px solid #EAEAEA"
            // boxShadow="0px 2px 4px 0px #00000029"
            >
              <Outlet />
            </Stack>
          </Stack>
      }
    </>
  )
}

export default Layout